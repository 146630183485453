





















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Movement } from '@/models/Movement'
import { formatDate } from '@/utils'

@Component
export default class ExplorerTable extends Vue {
	@Prop() loading!: boolean
	@Prop() itemsPerPageOption!: number[]
	@Prop() movements!: Movement[]
	@Prop() offset!: number
	@Prop() searchedAccounts?: string
	@Prop() size!: number
	@Prop() total!: number

	headers = [
	  {
		  align: 'left',
		  sortable: false,
		  text: this.$t('explorer.createdAt'),
		  value: 'at',
	  },
		{
			align: 'left',
			sortable: false,
			text: this.$t('explorer.type'),
			value: 'type',
		},
		{
			align: 'left',
			sortable: false,
			text: this.$t('explorer.accountFromId'),
			value: 'accountFromId',
		},
		{
			align: 'center',
			sortable: false,
			value: 'inOut',
		},
		{
			align: 'left',
			sortable: false,
			text: this.$t('explorer.accountToId'),
			value: 'accountToId',
		},
		{
			align: 'left',
			sortable: false,
			text: this.$t('explorer.amount'),
			value: 'amount',
		},
	  {
		  align: 'left',
		  sortable: false,
		  text: this.$t('explorer.token'),
		  value: 'currency',
	  },
	]

	get page (): number {
		return this.offset / this.size + 1
	}

	set page (p: number) {
		this.$emit('onPageChanged', {
			page: p,
		})
	}

	async selectAccount (id: string): Promise<void> {
		// console.log('-- selectAccount ' + id)
		this.$emit('onSelectedAccountChanged', id)
	}

	async onRowsPerPageChanged (item: number): Promise<void> {
		// console.log('-- onRowsPerPageChanged', item)
		this.$emit('onRowsPerPageChanged', {
			size: item,
		})
	}

	async onPageChanged (page: number): Promise<void> {
		this.$emit('onPageChanged', {
			page: page,
		})
	}

	formatDate (date: Date): string {
		return formatDate(date, this.$i18n)
	}
}
