





























































import ExplorerTable from '@/components/ExplorerTable.vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { getExplorerData } from '@/clients/cpinblocks'
import { Movement } from '@/models/Movement'
import { DataExplorer } from '@/models/DataExplorer'
import { BigNumber } from 'bignumber.js'
import type { Currency } from '@/types'

@Component({
	components: {
		ExplorerTable,
	},
})
export default class ExplorerView extends Vue {
	@Prop()
	currency?: Currency

	@Prop()
	date?: string

	@Prop()
	accountId?: string

	@Prop()
	offset?: number

	@Prop()
	size?: number

	currentDate: string | null = null
	currentAccount: string | null = null
	currentOffset = 0
	currentSize = 10
	itemsPerPageOption = [10, 25, 50, 100]
	total?: number = 0
	loading = true
	menu = false
	dataExplorer: DataExplorer | null = null
	movements: Movement[] = []
	balance?: BigNumber | null = null

	async onRowsPerPageChanged (event: any): Promise<void> {
		// console.log('onRowsPerPageChanged', event)
		this.currentSize = event.size
		await this.refresh()
	}

	async onPageChanged (event: any): Promise<void> {
		// console.log('onPageChanged ' + event.page)
		this.currentOffset = (event.page - 1) * this.currentSize
		await this.refresh()
	}

	async onDateChanged (date: string): Promise<void> {
		// console.log('onDateChanged ' + date)
		this.currentOffset = 0
		this.currentDate = date
		await this.refresh()
	}

	@Watch('currency')
	async refresh (): Promise<void> {
		// makes sure there is always a date or a accountId
		if (!this.currentAccount || !this.currentAccount.startsWith('0x')) {
			if (!this.currentDate) {
				// this.currentDate = new Date().toISOString().substring(0, 10)
			}
		}
		await this.updatePath()
		this.loading = true
		this.movements = []
		this.dataExplorer = await getExplorerData(
			this.$store.state.jwt,
			this.currency,
			this.currentSize,
			this.currentOffset,
			this.currentAccount && this.currentAccount.startsWith('0x') ? this.currentAccount.substring(2) : undefined,
			this.currentDate !== null ? this.currentDate : undefined,
			this.currentDate !== null ? this.currentDate : undefined
		)
		if (this.dataExplorer === null) {
			this.movements = []
			this.total = 0
		} else {
			this.movements = this.dataExplorer.movements
			this.total = this.dataExplorer.total
		}
		this.loading = false
	}

	private async mounted (): Promise<void> {
		if (this.date) {
			this.currentDate = this.date
		}
		if (this.accountId) {
			this.currentAccount = this.accountId
		}
		if (this.size && this.itemsPerPageOption.indexOf(this.size) >= 0) {
			this.currentSize = this.size
		}
		if (this.offset) {
			this.currentOffset = this.offset
		}
		await this.refresh()
		this.loading = false
	}

	async updatePath (): Promise<boolean> {
		const query = { } as any
		if (this.currentDate !== null) {
			query.date = this.currentDate
		}
		if (this.currentAccount !== null) {
			query.accountId = this.currentAccount
		}
		if (this.currentSize) {
			query.size = this.currentSize.toString()
		}
		if (this.currentOffset) {
			query.offset = this.currentOffset.toString()
		}
		let modified = false
		for (const k of Object.keys(query)) {
			if (query[k] !== this.$route.query[k]) {
				modified = true
			}
		}
		for (const k of Object.keys(this.$route.query)) {
			if (query[k] !== this.$route.query[k]) {
				modified = true
			}
		}
		if (modified) {
			await this.$router.replace({ query: query })
		}

		return modified
	}

	async onSelectedAccountChanged (accountId: string | null): Promise<void> {
		this.currentAccount = accountId
		this.currentOffset = 0
		await this.refresh()
	}

	async clearSearch (): Promise<void> {
		this.currentAccount = null
		await this.refresh()
	}

	async clearDate (): Promise<void> {
		this.currentDate = null
		await this.refresh()
	}
}
